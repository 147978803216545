import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

const Header = ({ siteTitle }) => (
  <header>
    <div className="flex flex-wrap items-center justify-between max-w-4xl p-4 mx-auto md:p-8">
      <Link to="/" className="flex items-center"> {siteTitle} </Link>
      <nav className="flex items-center justify-end">
        <Link to="/about" className="mr-6">
          About
        </Link>
        <Link to="/contact" className="mr-6">
          Contact
        </Link>
        <Link to="/blog" className="mr-6">
          Blog
        </Link>
        <Link to="/projects" className="mr-6">
          Projects
        </Link>
      </nav>
    </div>
      
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
