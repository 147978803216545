import * as React from "react"
import { FireIcon } from "@heroicons/react/solid"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Link } from "gatsby"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <main className="h-screen bg-slate-200 dark:bg-slate-800 dark:text-slate-200">
        <div className="w-full mx-auto pt-16 pb-24">
            <div className="w-full px-2 sm:px-2 mb-6 md:mb-0">
              <div className="items-center justify-center">
                <div className="text-center">
                  <div className="flex justify-center">
                    <FireIcon className="h-16 w-16 text-red-500" />
                  </div>
                  <h1 className="text-5xl font-bold text-slate-800 dark:text-slate-200">
                    Oops!
                  </h1>
                  <h2 className="text-2xl font-bold text-slate-800 dark:text-slate-200">
                    404 - Page Not Found
                  </h2>
                  <p className="text-slate-600 dark:text-slate-400">
                    The page you are looking for does not exist.
                  </p>
                  <Link to="/" className="inline-block mt-8 text-blue-500">
                    Go back home
                  </Link>
                </div>
              </div>
            </div>
          </div>
      </main>
  </Layout>
)

export default NotFoundPage
